.main[data-host="justcases.org"],
.main[data-host="hellcases.org"],
.main[data-host="csgohappy.com"],
.main[data-host="key-dropp.net"] {
  .footer {
    .logo {
      &:hover {
        &:before {
          opacity: 0;
        }

        img {
          opacity: 1;
        }
      }
    }
  }
}

.footer {
  // background: linear-gradient(#020111 0%, rgba(44, 6, 87, 0.7) 30.08%, rgba(11, 2, 32, 0) 100%);
  z-index: 4;
  position: relative;

  .open-cases-btns {
    margin-bottom: 33px;
  }

  .menu {
    max-width: 990px;
    padding: 23px 0 0 17px;
    margin: 0 auto 47px auto;

    @media (max-width: 1440px) {
      max-width: 100%;
    }
    @media (max-width: 1024px) {
      padding-top: 32px;
    }

    ul {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1440px) {
        justify-content: center;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #ccc;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.85;
        letter-spacing: 0.33px;
        text-transform: uppercase;
        position: relative;
        padding: 0 22px 0 22px;
        min-width: 136px;

        &:before {
          @extend %before-after;
          width: 1px;
          height: 90px;
          opacity: 0.2;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          background: -moz-linear-gradient(
            top,
            rgba(176, 195, 217, 0) 0%,
            rgba(176, 195, 217, 1) 50%,
            rgba(176, 195, 217, 0) 100%
          );
          background: -webkit-linear-gradient(
            top,
            rgba(176, 195, 217, 0) 0%,
            rgba(176, 195, 217, 1) 50%,
            rgba(176, 195, 217, 0) 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(176, 195, 217, 0) 0%,
            rgba(176, 195, 217, 1) 50%,
            rgba(176, 195, 217, 0) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b0c3d9', endColorstr='#00b0c3d9', GradientType=0);
        }

        &:after {
          @extend %before-after;
          width: 112px;
          height: 112px;
          background: url(../images/menu-shadow.svg) no-repeat 0 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          opacity: 0;
          transition: all 0.7s;
          margin-top: -5px;
        }

        .img {
          position: relative;
          width: 64px;
          height: 64px;

          &:before {
            @extend %before-after;
            z-index: -1;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 17px;
            height: 17px;
            transform: translate(-50%, -50%);
            box-shadow:
              0px 0px 22px #31220c,
              0px 0px 62px #ffdf77;
            border-radius: 50%;
          }

          &:after {
            @extend %before-after;
            top: 50%;
            left: 50%;
            width: 74px;
            height: 74px;
            background: -moz-radial-gradient(
              center,
              ellipse cover,
              rgba(255, 223, 119, 1) 0%,
              rgba(255, 223, 119, 0) 80%
            );
            background: -webkit-radial-gradient(
              center,
              ellipse cover,
              rgba(255, 223, 119, 1) 0%,
              rgba(255, 223, 119, 0) 80%
            );
            background: radial-gradient(ellipse at center, rgba(255, 223, 119, 1) 0%, rgba(255, 223, 119, 0) 80%);
            transform: translate(-50%, -50%);
            opacity: 0.1;
          }
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &.hover {
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all 0.7s;
          }
        }

        &:hover {
          img.hover {
            opacity: 1;
          }

          span {
            color: #ffdf77;
          }

          &:after {
            opacity: 1;
          }
        }

        span {
          white-space: nowrap;
        }
      }

      & > :last-child {
        a:before {
          display: none;
        }

        .img:after {
          background: -moz-radial-gradient(center, ellipse cover, rgba(94, 12, 119, 1) 0%, rgba(57, 15, 99, 0) 86%);
          background: -webkit-radial-gradient(center, ellipse cover, rgba(94, 12, 119, 1) 0%, rgba(57, 15, 99, 0) 86%);
          background: radial-gradient(ellipse at center, rgba(94, 12, 119, 1) 0%, rgba(57, 15, 99, 0) 86%);
        }
      }
    }
  }

  &-content {
    display: flex;
    max-width: 1010px;
    margin: 0 auto 30px auto;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1500px) {
      max-width: 900px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      text-align: center;
      margin-bottom: 32px;
    }

    & > :nth-child(3) {
      @media (max-width: 1024px) {
        order: 1;
      }
    }

    & > :nth-child(2) {
      @media (max-width: 1024px) {
        order: 2;
      }
    }

    & > :nth-child(1) {
      @media (max-width: 1024px) {
        order: 3;
      }
    }

    .footer-top-item {
      width: 280px;

      .agree {
        margin-top: 8px;
        margin-bottom: 21px;

        @media (max-width: 1024px) {
          margin-top: 0;
          margin-bottom: 26px;
        }
      }
    }
  }

  .logo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    @media (max-width: 1600px) {
      max-width: 352px;
    }

    &:before {
      @extend %before-after;
      width: 486px;
      height: 122px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: url(../images/footer-logo.png) no-repeat 0 0;
      filter: blur(10px);
      opacity: 0;

      @media (max-width: 1600px) {
        width: 300px;
        background-size: 100%;
      }
    }

    img {
      position: relative;
      z-index: 2;
      width: 416px;
      margin-top: -30px;

      @media (max-width: 1600px) {
        width: 100%;
        height: auto;
      }
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &-grad {
    content: "";
    display: block;
    background: linear-gradient(to top, #020111 0%, rgba(44, 6, 87, 0.7) 30.08%, rgba(11, 2, 32, 0) 100%);
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 70px;
    z-index: -1;
  }

  &.footer-profile-landing {
    background: linear-gradient(0deg, #020111 0%, rgba(44, 6, 87, 0.7) 22%, rgba(11, 2, 32, 0.0001) 100%);
    overflow: hidden;

    &:before,
    &:after {
      bottom: -55px;
      height: 100%;
    }

    .footer-content {
      max-width: 1000px;
      padding-bottom: 23px;

      .logo {
        max-width: initial;
      }
    }

    .menu {
      margin-bottom: 25px;
      padding-left: 0;
      max-width: 830px;
      padding-left: 0;

      .img {
        position: relative;
        top: 0;
        left: 0;
      }

      ul {
        padding-left: 0;
        justify-content: center;

        a {
          padding: 0;
          min-width: 112px;
          margin: 0 12px;

          &:after {
            background: radial-gradient(50% 50% at 50% 50%, #753700 0%, rgba(57, 15, 99, 0.0001) 100%);
            mix-blend-mode: normal;
            opacity: 0.2;
            margin-top: -14px;
          }

          &:before {
            right: -12px;
          }

          &:hover {
            letter-spacing: 0.325px;
            text-transform: uppercase;
            color: #d32ce6;

            &:after {
              margin-top: -14px;
              opacity: 0.4;
            }

            span {
              color: inherit;
            }
          }

          .icon:before {
            top: 77px;
          }
        }

        img {
          transform: translate(18px, 52px);
        }
      }
    }
  }

  // .footer-address
  &-address {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    color: rgba(213, 185, 255, 0.24);
    margin-top: 20px;

    @media (max-width: 1600px) {
      font-size: 12px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 16px;
      margin-top: 11px;
      margin-bottom: 32px;
    }
  }

  .header-menu {
    margin: 64px 0 40px 0;
    justify-content: center;

    @media (max-width: 1200px) {
      display: flex;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0 16px;
      margin: 32px 0 32px 0;

      .header-menu__item {
        width: 100%;
        flex-direction: row;
        height: 40px;
        margin-bottom: 4px;
        padding: 4px;

        &:before {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 1px;
          background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.19) 0%, rgba(57, 15, 99, 0) 100%);
          mix-blend-mode: normal;
          opacity: 1;
        }

        &:after {
          background: radial-gradient(50% 3200% at 50% 50%, rgba(117, 55, 0, 0.192) 0%, rgba(57, 15, 99, 4e-5) 100%);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          filter: none;
          transform: none;
        }

        &-icon {
          width: 32px;
          height: 32px;
          margin-right: 8px;

          &:before {
            display: none;
          }
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }

        &.active {
          &:after {
            background: radial-gradient(50% 3200% at 50% 50%, rgba(111, 0, 123, 0.4) 0%, rgba(57, 15, 99, 4e-5) 100%);
            opacity: 1;
          }
        }

        &-name {
          font-weight: 400;
        }
      }
    }

    &__item-icon {
      width: 48px;
      height: 48px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.copyright {
  font-size: 14px;
  line-height: 16px;
  color: rgba(230, 155, 255, 0.64);
  margin-top: 2px;
  margin-bottom: 8px;

  @media (max-width: 1024px) {
    margin: 0;
  }
}

.footer .agree a,
.footer .confed {
  font-size: 12px;
  line-height: 12px;
  color: rgba(230, 155, 255, 0.5);
  text-decoration: none;
  margin-bottom: 6px;
  display: inline-block;

  @media (max-width: 1024px) {
    margin-bottom: 12px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.footer-support-mail {
  font-size: 14px;
  cursor: pointer;
  color: rgba(98, 151, 255, 0.72);
  text-decoration: none;
  margin-top: 4px;
  padding-bottom: 11px;
  display: inline-block;

  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 32px;
    padding-bottom: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}

.soc-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    justify-content: center;
  }

  a {
    width: 64px;
    height: 64px;
    position: relative;

    &.telegram {
      background: url(../images/telegramstatic.svg) no-repeat 50% 0;

      &:hover {
        &:before {
          background: url(../images/telegramhover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.vk {
      background: url(../images/footer-vk.svg) no-repeat 50% 0;

      &:hover {
        &:before {
          background: url(../images/footer-vk-hover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.instagram {
      background: url(../images/footer-inst.svg) no-repeat 50% 0;

      &:hover {
        &:before {
          background: url(../images/footer-inst-hover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.fb {
      background: url(../images/footer-fb.svg) no-repeat 50% 0;

      &:hover {
        &:before {
          background: url(../images/footer-fb-hover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.discord {
      background: url(../images/footer-discord.svg) no-repeat 50% 0;

      &:before {
        width: 88px;
        height: 88px;
      }

      &:hover {
        &:before {
          background: url(../images/footer-discord-hover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.tiktok {
      background: url(../images/icon-tiktok.svg) no-repeat 50% 50%;

      &:before {
        width: 88px;
        height: 88px;
      }

      &:hover {
        &:before {
          background: url(../images/icon-tiktok-hover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.youtube {
      background: url(../images/icon-youtube.svg) no-repeat 50% 50%;

      &:before {
        width: 88px;
        height: 88px;
      }

      &:hover {
        &:before {
          background: url(../images/icon-youtube-hover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.twitch {
      background: url(../images/icon-twitch.svg) no-repeat 50% 50%;

      &:before {
        width: 88px;
        height: 88px;
      }

      &:hover {
        &:before {
          background: url(../images/icon-twitch-hover.svg) no-repeat 0 0;
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &.tw {
      background: url(../images/footer-tw.svg) no-repeat 50% 50%;

      &:before {
        background: url(../images/footer-tw-hover.svg) no-repeat 50% 50%;
      }
      &:hover {
        &:before {
          opacity: 1;
          z-index: auto;
        }
      }
    }

    &:before {
      @extend %before-after;
      width: 112px;
      height: 112px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
  }
}

.footer-bot {
  width: 1125px;
  margin: 0 auto;
  padding: 39px 60px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  cursor: pointer;
  position: relative;
  text-align: center;

  @media (max-width: 1440px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-width: 1024px) {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 20px 22px;
    flex-wrap: wrap;
  }
}

.footer-bot > a,
.footer-bot > div {
  cursor: pointer;
  filter: grayscale(100%) contrast(50%) brightness(60%);
  margin: 0 20px;

  @media (max-width: 1024px) {
    margin: 0 6px;
  }
  @media (max-width: 768px) {
    width: calc(33.3% - 12px) !important;
    background-position: 50% 50% !important;
  }
  @media (max-width: 540px) {
    width: calc(50% - 12px) !important;
  }
}

.footer-bot > div:hover {
  filter: grayscale(0) contrast(100%) brightness(100%);
}

.footer-bot .pay-1 {
  width: 68px;
  height: 53px;
  background: url(../images/mastercard.svg) no-repeat;
}

.footer-bot .pay-2 {
  width: 107px;
  height: 34px;
  background: url(../images/visa.svg) no-repeat;
}

.footer-bot .pay-3 {
  width: 150px;
  height: 38px;
  background: url(../images/webmoney.svg) no-repeat;
}

.footer-bot .pay-4 {
  width: 123px;
  height: 45px;
  background: url(../images/qiwi.svg) no-repeat;
}

.footer-bot .pay-5 {
  width: 134px;
  height: 53px;
  background: url(../images/yandex.svg) no-repeat;
  margin-top: 20px;
  background-size: contain;
}

.footer-bot .pay-6 {
  width: 122px;
  height: 53px;
  background: url(../images/alikassa.png) no-repeat;
  background-size: contain;
}

.footer-bot img {
  width: 122px !important;
  height: 41px !important;
}

.footer:after,
.footer:before {
  @extend %before-after;
  position: absolute;
  bottom: 0;
  width: 960px;
  height: 635px;
  z-index: 2;
  pointer-events: none;

  @media (max-width: 1800px) {
    width: 690px;
    height: 485px;
  }
}

.footer:before {
  left: 0;
  background: url(../images/footer-man-left.webp) 0 100% no-repeat;
  background-size: auto 100%;

  @media (max-width: 1660px) {
    width: 490px;
    height: 360px;
  }
  @media (max-width: 1360px) {
    width: 370px;
    background-size: 100%;
  }
  @media (max-width: 1280px) {
    display: none !important;
  }
}

.footer:after {
  right: 0;
  background: url(../images/footer-man-right.webp) 100% 100% no-repeat;
  background-size: auto 100%;

  @media (max-width: 1660px) {
    width: 490px;
    height: 360px;
  }
  @media (max-width: 1360px) {
    width: 370px;
    background-size: 100%;
  }
  @media (max-width: 1280px) {
    display: none !important;
  }
}

.main[data-host="cscase.com"] .footer-bot .pay-3 {
  //width: 152px;
  //height: 64px;
  //background: url(../images/neteller.svg) no-repeat;
  background: url(../images/zen.svg) no-repeat;
  height: 44px;
  width: 109px;
  background-size: contain;
}

.main[data-host="cscase.com"] .footer-bot .pay-4 {
  width: 152px;
  height: 64px;
  background: url(../images/icon-pwc.svg) no-repeat 50% 50%;
}

.main[data-host="cscase.com"] .footer-bot .pay-5 {
  width: 152px;
  height: 64px;
  background: url(../images/g2a.svg) no-repeat;
  margin-top: 0;
}

.main[data-host="cscase.com"] .footer-bot .pay-6 {
  width: 152px;
  height: 64px;
  background: url(../images/skrill.svg) no-repeat;
}

// .main[data-region='cis'] .footer-bot .pay-6 {
//   width: 152px;
//   height: 64px;
//   background: url(../images/icon-pwc.svg) no-repeat 50% 50%;
// }

.footer-trustpilot {
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;

  @media (max-width: 1024px) {
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  img {
    max-width: 100%;
    display: block;
  }
}

.trustpilot {
  text-decoration: none;
  color: rgba(230, 155, 255, 0.4);

  span {
    color: #ffbbff;
  }

  &-text {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    text-align: right;
  }

  &-stars {
    margin: 0 -1px;
    display: flex;
  }

  &-stars-wrap {
    display: flex;
    align-items: center;
  }

  &-stars-logo {
    margin-right: 16px;
  }

  &-stars-item {
    background: linear-gradient(0deg, #8214a8 0%, #ff6ce3 100%);
    width: 20px;
    height: 20px;
    margin: 0 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
